<template>
  <div class="detail-page" :class="formData.repair_status != 0 && 'pb-none'">
    <div class="content">
      <template v-for="(item, index) of fieldList" :key="index">
        <div class="item none-border" v-if="item.type == 'textarea'">
          <label for="">{{ item.text }}</label>
          <van-field
            class="textarea"
            disabled
            v-model="formData[item.field]"
            rows="3"
            autosize
            type="textarea"
          />
        </div>
        <div class="item" v-else>
          <label for="">{{ item.text }}</label>
          <span>{{ formData[item.field] }}</span>
        </div>
      </template>
    </div>
    <div class="state content">
      <div class="item center" v-if="!(formData.repair_status == 0 || formData.repair_status == 3)">
        <label for="">维修人</label>
        <div class="check-done">
          <div class="check-user">{{ formData.worker_name }} {{ formData.worker_mobile }}</div>
          <img
            src="@/assets/image/phone1.svg"
            alt="呼叫"
            @click="common.callPhone(item.worker_mobile)"
          />
        </div>
      </div>
      <div class="item none-border">
        <label for="">维修状态</label>
        <span
          :class="formData.repair_status == 1 || formData.repair_status == 2 ? 'check' : 'no-check'"
          >{{ statusMap(formData.repair_status) }}</span
        >
      </div>
    </div>
    <div class="detail">
      <div class="title">相关图片</div>
      <div class="img-box">
        <template v-for="(item, index) of imgList" :key="index">
          <div class="img-item-box">
            <img v-preview :src="item" alt="" />
          </div>
        </template>
      </div>
    </div>
    <div class="operation" v-if="formData.repair_status == 0">
      <div class="button" @click="hanldeEdite">去修改</div>
    </div>
  </div>
</template>
<script setup>
import { ref, inject, onMounted } from 'vue';
import { statusMap } from './config';
import * as common from '@/plugin/public';

const { route, router } = inject('ctx');
const $require = inject('$require');
const id = route.query.id;
const imgList = ref([]);
const fieldList = [
  {
    text: '报修设备',
    field: 'repair_type'
  },
  {
    text: '报修人',
    field: 'merchants_name'
  },
  {
    text: '摊位',
    field: 'address'
  },
  {
    text: '提交时间',
    field: 'create_at'
  },
  {
    text: '报修内容',
    field: 'repair_details',
    type: 'textarea'
  }
];
const formData = ref({});
onMounted(() => {
  getDetail();
});
const getDetail = () => {
  const params = {
    id
  };
  $require.HttpGet('/h5/repair/edit', params).then((res) => {
    formData.value = res.data;
    imgList.value = JSON.parse(formData.value.repair_images);
  });
};
const hanldeEdite = () => {
  router.replace({
    path: '/repair/apply',
    query: {
      id: formData.value.repair_id //修改携带
    }
  });
};
</script>
<style lang="scss" scoped>
.detail-page {
  font-size: 28px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  height: 100vh;
  padding: 18px 32px 128px 32px;
  overflow-y: scroll;

  .content {
    background-color: #fff;
    border-radius: 10px;
    .item {
      min-height: 78px;
      line-height: 78px;
      padding: 0 24px;
      border-bottom: 1px solid #efefef;
      label {
        display: inline-block;
        min-width: 150px;
      }
      span {
        color: #000;
      }
    }
  }
  .state {
    margin-top: 20px;
    margin-bottom: 20px;
    .check-done {
      display: inline-flex;
      align-items: center;
      .check-user {
        margin-right: 24px;
        color: #000000;
      }
      img {
        width: 64px;
      }
    }
  }
  .detail {
    background-color: #fff;
    border-radius: 10px;
    padding: 0 24px;

    .title {
      height: 88px;
      line-height: 88px;
    }
    .img-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .img-item-box {
        width: 308px;
        height: 308px;
        margin-bottom: 22px;
        border-radius: 10px 10px 10px 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .operation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;

    .button {
      width: 686px;
      height: 96px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #85c226;
      font-size: 36px;
      background: #ffffff;
      border-radius: 200px 200px 200px 200px;
      opacity: 1;
      border: 2px solid rgba(0, 0, 0, 0.1);
    }
  }
}
.none-border {
  border-bottom: none !important;
}
.check {
  color: #85c226 !important;
}
.no-check {
  color: #fd6336 !important;
}
.pb-none {
  padding-bottom: 0;
}
.center {
  height: 105px;
  line-height: 105px !important;
}
</style>
