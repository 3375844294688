const statusMap = status => {
  let str = '';
  switch (status) {
    case 0:
      str = '待接单';
      break;
    case 1:
      str = '已接单';
      break;
    case 2:
      str = '已处理';
      break;
    case 3:
      str = '已拒绝';
      break;
    default:
      str = '';
      break;
  }
  return str;
};

export { statusMap };
